<template>
    <div class="education-wrapper">
        <div class="education-header">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item><span style="font-weight: 600; color: #303133 !important;">运营推广管理</span></el-breadcrumb-item>
                <el-breadcrumb-item>店铺选品</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div style="display: flex; justify-content: space-between; align-items: center; margin-top: 20px">
            <IndustrySelection @selectListChange="selectListChange" text="行业分类：" :selectList="selectListArr"></IndustrySelection>
            <div>
                <el-button class="btn-blue" @click="sendBtn" style="display: inline-block;">发送</el-button>
                <el-button class="btn-blue" @click="addBtn">创建选品数据</el-button>
            </div>
        </div>
        <el-table ref="multipleTable" :data="selectList" border style="width: 100%; margin-top: 20px; flex: 1" height="1%" size="medium"
                  :header-cell-style="{fontWeight: 'normal', height: '60px', color: '#13131B', background: '#F2F3FA'}"
                  :cell-style="{fontSize: '14px',color: '#343441'}">
            <el-table-column type="selection"></el-table-column>
            <el-table-column align="center" label="店铺选品名称">
                <template slot-scope="scope">
                    <p class="text-overflow-2" :title="scope.row.name">{{scope.row.name}}</p>
                </template>
            </el-table-column>
            <el-table-column align="center" label="备注">
                <template slot-scope="scope">
                    <p class="text-overflow-2" :title="scope.row.bz">{{scope.row.bz}}</p>
                </template>
            </el-table-column>
            <el-table-column prop="industry_name" align="center" label="行业分类"></el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <el-link :underline="false" class="link-blue" @click="copyBtn(scope.row)">复制</el-link>
                    <el-link :underline="false" class="link-blue" @click="editBtn(scope.row)">编辑</el-link>
                    <el-link :underline="false" type="danger" @click="delBtn(scope.row.id)">删除</el-link>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination class="pager-center" style="margin-top: 10px;"
                       :current-page="listPages.currentPageNum"
                       :page-size="listPages.eachPageNum"
                       :total="listPages.total"
                       layout="prev, pager, next, jumper"
                       @current-change="pagesCurrentChange">
        </el-pagination>
    </div>
</template>

<script>
    import {copyStoreSelection, postStoreSelectionSend} from "../../../utils/apis";
    import IndustrySelection from "../IndustrySelection";

    export default {
        components: {
            IndustrySelection
        },
        data() {
            return {
                selectList: [],
                listPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0,
                },
                selectListArr: [],
                selectVal: void 0,
            }
        },
        mounted() {
            this.getSelectList()
            this.getKeFuGetIC()
        },
        methods: {
            // 点击发送
            async sendBtn(){
                let id =this.$refs.multipleTable.selection.map((item) => item.id);
                if(id && id.length > 0){
                    const result = await this.$common.sendData(postStoreSelectionSend, { ids: id });
                    result && this.getKeywordList();
                }else{
                    this.$message.error("请勾选需要发送的数据！")
                }
            },
            selectListChange(id) {
                this.selectVal = id;
                this.getSelectList(id)
            },
            // 获取行业分类
            getKeFuGetIC() {
                this.$http.axiosGetBy(this.$api.categoryList, {}, (res) => {
                    if (res.code === 200) {
                        this.selectListArr = res.data;
                    } else {
                        this.$message.warning(res.msg);
                    }
                });
            },
            // 获取店铺选品列表
            getSelectList(id) {
                let params = {
                    paging: 1,
                    page: this.listPages.currentPageNum,
                    pageSize: this.listPages.eachPageNum,
                    category_id: id ? id : ''
                }
                this.$http.axiosGetBy(this.$api.storeSelection, params, (res) => {
                    if (res.code === 200) {
                        this.selectList = res.data.data
                        this.listPages.total = res.data.total
                        if (this.listPages.total !== 0 && this.selectList.length === 0) {
                            this.listPages.currentPageNum--;
                            this.getSelectList(this.selectVal);
                        }
                    } else {
                        this.$message.warning(res.msg)
                    }
                }, (err) => {
                    console.log(err)
                })
            },






            // 切换分页
            pagesCurrentChange(val) {
                this.listPages.currentPageNum = val
                this.getSelectList()
                this.getSelectList(this.selectVal)
            },
            // 添加
            addBtn() {
                this.$router.push('/admin/operatedata/storeselection/create')
            },
            // 编辑
            editBtn(row) {
                if (row.used) {
                    this.$message.warning('该店铺选品正在使用中，不可编辑')
                } else {
                    this.$router.push({
                        path: '/admin/operatedata/storeselection/create',
                        query: {
                            id: row.id
                        }
                    })
                }
            },
            async copyBtn(val) {
                const valid = await this.$common.copyTemplatePack(copyStoreSelection, { id: val.id });
                valid && this.getSelectList(this.selectVal);
            },
            // 删除
            delBtn(id) {
                this.$confirm('是否删除该店铺选品，删除完将无法恢复', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    confirmButtonClass: 'btn-blue',
                    type: 'warning',
                }).then(() => {
                    this.$http.axiosPost(this.$api.storeSelectionDel, {id: id}, (res) => {
                        if (res.code === 200) {
                            this.$message.success(res.msg)
                            this.getSelectList(this.selectVal)
                        } else {
                            this.$message.warning(res.msg)
                        }
                    }, (err) => {
                        console.log(err)
                    })
                }).catch(() => {
                    this.$message.info('已取消删除')
                });
            }
        }
    }
</script>

<style scoped lang="scss">
    ::v-deep p {
        margin: 0;
    }
    .education-wrapper {
        padding: 30px;
        height: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        .education-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .el-breadcrumb {
                line-height: 40px;
            }
        }
    }
    .el-link + .el-link {
        margin-left: 10px;
    }
</style>